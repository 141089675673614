exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-empresa-js": () => import("./../../../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reportes-estudiantes-js": () => import("./../../../src/pages/reportes/estudiantes.js" /* webpackChunkName: "component---src-pages-reportes-estudiantes-js" */),
  "component---src-pages-reportes-key-js": () => import("./../../../src/pages/reportes/key.js" /* webpackChunkName: "component---src-pages-reportes-key-js" */),
  "component---src-pages-reportes-login-js": () => import("./../../../src/pages/reportes/login.js" /* webpackChunkName: "component---src-pages-reportes-login-js" */)
}

